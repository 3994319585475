.header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
}

.header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 170px;
    position: relative;
}

.logo {
    height: 40px;
}

nav a {
    font-family: 'Lato', sans-serif;
    font-size: 14px;
    font-weight: 500;
    color: white;
    text-decoration: none;
    margin-left: 30px;
    letter-spacing: 0.02em;
}

/* Mobile styles */
@media (max-width: 768px) {
    .header-content {
        padding: 20px 10px;
    }

    .hamburger-icon {
        display: block;
        cursor: pointer;
        font-size: 24px;
        color: white;
        position: relative;
        /* Ensure it’s above the overlay */
        z-index: 1010;
        /* Make sure it's above the overlay */
    }

    nav {
        display: none;
    }

    .nav-links {
        display: flex;
        flex-direction: column;
        position: fixed;
        top: 0;
        left: 0;
        width: 250px;
        /* Adjust width as needed */
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.2);
        /* Semi-transparent background */
        padding-top: 60px;
        /* Space for logo or padding */
        padding-left: 20px;
        /* Padding on the left */
        text-align: left;
        justify-content: flex-start;
        /* Align text to the top */
        transition: transform 0.3s ease-in-out;
        transform: translateX(-100%);
        /* Hide initially */
        z-index: 1000;
        /* Ensure it's behind the close icon */
    }

    .nav-links.show {
        transform: translateX(0);
        /* Slide in from the left */
    }

    .nav-links a {
        color: white;
        font-size: 20px;
        margin: 20px 0;
        padding-left: 20px;
        /* Padding on the left */
    }

    .overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.2);
        /* Semi-transparent overlay */
        z-index: 999;
        /* Ensure it's below the close icon */
    }

    /* Background blur effect */
    .background-blur {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        backdrop-filter: blur(5px);
        /* Adjust blur level as needed */
        z-index: 998;
        /* Ensure it’s below the overlay */
    }
}

@media (min-width: 769px) {
    .hamburger-icon {
        display: none;
    }
}