.footer {
    color: #fff;
    /* White text color */
    padding: 20px;
}

.footer-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;
}

.footer-left {
    display: flex;
    align-items: center;
    gap: 10px;
}

.footer-logo {
    font-size: 14px;
    font-weight: 800;
}

.footer-copyright {
    font-size: 14px;
    font-weight: 400;
}

.social-icons {
    display: flex;
    gap: 30px;
}

.social-icon {
    color: #fff;
    font-size: 18px;
    transition: color 0.3s;
}

.social-icon:hover {
    color: #ccc;
}

.email-form {
    margin-bottom: 200px;
}

@media (max-width: 768px) {
    .footer-content {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .footer-left {
        margin-bottom: 10px;
    }

    .social-icons {
        display: none;
    }
}