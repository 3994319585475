body {
  margin: 0;
  padding: 0;
  font-family: 'Lato', sans-serif;
  background-color: #13111C;
  color: #fff;
}

html {
  scroll-behavior: smooth;
}

.App {
  min-height: 100vh;
}