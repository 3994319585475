.email-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 0px;
}

.email-form-logo {
    width: 80px;
    /* Logo size */
    height: 80px;
}

.email-form-title {
    font-size: 20px;
    font-weight: 800;
    text-align: center;
    margin-bottom: 28px;
}

.email-form-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.input-container {
    display: flex;
    align-items: center;
    width: 220px;
    height: 40px;
    background-color: rgba(255, 255, 255, 0.05);
    border: 1px solid rgba(0, 0, 0, 0.10);
    border-radius: 11px;
}

.email-icon {
    font-size: 20px;
    color: rgba(255, 255, 255, 0.25);
    margin-left: 10px;
}

.email-input {
    flex: 1;
    height: 100%;
    background: transparent;
    border: none;
    padding-left: 10px;
    font-size: 14px;
    color: #FFFFFF;
    outline: none;
}

.email-input::placeholder {
    color: rgba(255, 255, 255, 0.25);
}

.submit-button {
    width: 45px;
    height: 40px;
    background-color: #D4ADFC;
    border: 1px solid rgba(0, 0, 0, 0.10);
    border-radius: 10px;
    margin-left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.arrow-icon {
    font-size: 18px;
    color: #13111C;
}

.submit-button:hover {
    background-color: #c3a0f3;
}

.submit-button:focus {
    outline: none;
}

.form-message {
    margin-top: 10px;
    font-size: 14px;
    color: #4caf50; /* Success message color */
  }
  
  .form-message.error {
    color: #f44336; /* Error message color */
  }