.app-icon {
    width: 50px;
    height: 50px;
    transition: transform 0.01s ease, opacity 0.01s ease;
}

@media (max-width: 900px) {
    .app-icon {
        width: 30px !important;
        height: 30px !important;
    }
}
