.main-content {
    min-height: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
    padding: 20px;
    margin-top: 20px;
}

.app-icon {
    position: absolute;
    width: 50px;
    height: 50px;
    transition: transform 0.3s ease-out;
    pointer-events: none;
}

h1 {
    font-size: 50px;
    font-weight: 1000;
    color: white;
    margin-bottom: 4px;
    z-index: 2;
}

p {
    font-size: 16px;
    font-weight: 100;
    color: rgba(255, 255, 255);
    margin-bottom: 30px;
    z-index: 2;
    letter-spacing: 0.03em;
}

p strong {
    color: #D4ADFC;
    font-weight: 800;
}

button {
    background-color: #D4ADFC;
    color: #000;
    border: 2px solid rgba(0, 0, 0, 0.1);
    padding: 0;
    font-size: 12px;
    font-weight: 600;
    border-radius: 12px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    z-index: 2;
    width: 164px;
    height: 40px;
    letter-spacing: 0.02em;
}

button:hover {
    background-color: #b78de0;
}

.play-icon {
    margin-right: 10px;
}

/* Responsive design */
@media (max-width: 768px) {
    h1 {
        font-size: 30px;
    }

    p {
        font-size: 14px;
    }

    button {
        font-size: 12px;
    }
}